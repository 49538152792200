<template>
  <div class="row" v-if="selectedTabIndex === 1">
    <div class="row">
      <div class="small-12 columns integrations-wrap">
        <div class="row integrations">
          <div class="small-12 columns integration">
            <div class="row">
              <div class="integration--image">
                <img
                  src="https://app.wintook.com/dashboard/images/integrations/EVAi.svg"
                />
              </div>
              <div class="column">
                <div class="column">
                  <h3 class="integration--title">Bot Eva</h3>
                  <p class="integration--description">
                    Permite gestionar consultas por medio de palabras reservadas
                    a sistemas administrativos y palabras no reservadas a blogs,
                    todo esto por medio de los canales de comunicación Web y
                    Telegram.
                  </p>
                </div>
              </div>
              <div class="small-2 column button-wrap">
                <button
                  @click="conectarEva"
                  class="button nice"
                  ref="conectorEva"
                >
                  {{ this.estadoEva }}
                </button>
              </div>
            </div>
            <BlogsEva />
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="row">
      <div class="small-12 columns integrations-wrap">
        <div class="row integrations">
          <div class="small-12 columns integration">
            <div class="row">
              <div class="integration--image">
                <img
                  src="https://upload.wikimedia.org/wikipedia/commons/0/04/ChatGPT_logo.svg"
                />
              </div>
              <div class="column">
                <div class="column">
                  <h3 class="integration--title">API ChatGTP</h3>
                  <p class="integration--description">
                    ChatGPT se basa en una red neuronal de lenguaje natural que
                    ha sido entrenada en una gran cantidad de datos de texto y
                    es capaz de generar respuestas coherentes y relevantes a
                    partir de las entradas del usuario.
                  </p>
                  <div class="column">
                    <div class="small-6 column">
                      <form class="row" @submit.prevent="setChatGTP">
                        <div class="medium-12 columns">
                          <label
                            >{{ "Api Key ChatGTP" }}
                            <input
                              v-model.trim="api_key_gpt"
                              type="text"
                              :placeholder="'Escriba el valor de la Api Key ChatGTP'"
                            />
                          </label>
                        </div>

                        <div class="medium-6 columns">
                          <label
                            >{{ "Modelo" }}
                            <select v-model="modelo_gpt">
                              <option
                                v-for="mg in this.modelos_gpt"
                                :key="mg.modelo_name"
                                :value="mg.modelo_name"
                              >
                                {{ mg.modelo_name }}
                              </option>
                            </select>
                          </label>
                        </div>
                        <div class="medium-1 columns"></div>
                        <div class="medium-5 columns">
                          <label
                            >{{ "Max-Token de Respuesta" }}
                            <input
                              v-model.trim="maxtoken_gpt"
                              type="number"
                              :placeholder="'Max-Token'"
                            />
                          </label>
                        </div>

                        <div class="modal-footer">
                          <div class="medium-12 columns">
                            <woot-submit-button
                              :button-text="'Conectar ChatGTP'"
                            />
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <AppTokenApi/>
  </div>
</template>
<script>
import axios from "axios";
import { mapGetters } from "vuex";
import BlogsEva from "./BlogsEva";
import AppTokenApi from "./AppTokenApi.vue";

export default {
  data() {
    return {
      conectadoEva: false,
      cuentasBlog: {},
      estadoEva: "Iniciar",
      catalogoBlogs: {},

      api_key_gpt: "",
      modelo_gpt: "",
      maxtoken_gpt: 50,
      modelos_gpt: {},
    };
  },

  components: {
    BlogsEva,
    AppTokenApi,
  },

  props: {
    selectedTabIndex: {
      type: Number,
      default: 0,
    },
  },

  computed: {
    ...mapGetters({
      currentUser: "getCurrentUser",
    }),
  },

  mounted() {
    this.getCuentasBlog();
    this.getModelosGPT();
    this.initializeData();
  },

  methods: {
    async initializeData() {
      // this.getCuentasBlog();
      this.getChatGTP();
      let token = this.currentUser.access_token;
      let conectado = await axios
        .get(process.env.URL_WEBHOOK + "/api/getConexionEva", {
          params: { token: token },
        })
        .then(function (resp) {
          return resp.data;
        })
        .catch(function (error) {
          return error;
        });
      this.conectadoEva = conectado.status;

      if (this.conectadoEva) {
        this.estadoEva = "Detener";
      } else {
        this.estadoEva = "Iniciar";
      }

      this.cuenta_blog_url = "";
      this.submitCuentaBlog = "Iniciar";
      this.activoSubmitCuentaBlog = false;
      this.activoInputCuentaBlogUrl = false;

      let result = await axios
        .get(process.env.URL_WEBHOOK + "/api/getCatalogoBlogs", {})
        .then(function (resp) {
          return resp.data;
        })
        .catch(function (error) {
          return error;
        });
      this.catalogoBlogs = result;
    },

    async getCuentasBlog() {
      const token = this.currentUser.access_token;
      const response = await axios
        .get(process.env.URL_WEBHOOK + "/api/getCuentasBlog", {
          params: { token: token },
        })
        .then(function (resp) {
          return resp.data;
        })
        .catch(function (error) {
          return error;
        });
      if (response.status === 200) {
        this.cuentasBlog = response.data;
      }
    },

    async setChatGTP() {},

    async conectarEva() {
      const token = this.currentUser.access_token;
      const conectado = await axios
        .get(process.env.URL_WEBHOOK + "/api/setConexionEva", {
          params: { token: token },
        })
        .then(function (resp) {
          return resp.data;
        })
        .catch(function (error) {
          return error;
        });
      this.conectadoEva = conectado.status;

      if (this.conectadoEva) {
        this.estadoEva = "Detener";
      } else {
        this.estadoEva = "Iniciar";
      }
    },

    async setChatGTP() {
      const { access_token, account_id } = this.currentUser;
      const api_key_gpt = this.api_key_gpt;
      const modelo_gpt = this.modelo_gpt;
      const maxtoken_gpt = this.maxtoken_gpt;
      const result = await axios
        .post(process.env.URL_WEBHOOK + "/api/setChatGTP", {
          params: {
            access_token,
            account_id,
            api_key_gpt,
            modelo_gpt,
            maxtoken_gpt,
          },
        })
        .then(function (resp) {
          return resp.data;
        })
        .catch(function (error) {
          return error;
        });

      if (result.status === 200) {
        bus.$emit("newToastMessage", result.toastMessage);
      }
    },

    async getChatGTP() {
      const { access_token, account_id } = this.currentUser;

      const result = await axios
        .get(process.env.URL_WEBHOOK + "/api/getChatGTP", {
          params: { access_token, account_id },
        })
        .then(function (resp) {
          return resp.data;
        })
        .catch(function (error) {
          return error;
        });

      if (result.status === 200) {
        this.api_key_gpt = result.api_key_gpt;
        this.modelo_gpt = result.modelo_gpt;
        this.maxtoken_gpt = result.maxtoken_gpt;
      }
    },

    async getModelosGPT() {
      const { access_token, account_id } = this.currentUser;

      const result = await axios
        .get(process.env.URL_WEBHOOK + "/api/getModelosGPT", {
          params: { access_token, account_id },
        })
        .then(function (resp) {
          return resp.data;
        })
        .catch(function (error) {
          return error;
        });

      if (result.status === 200) {
        this.modelos_gpt = result.models;
      }
    },
  },
};
</script>