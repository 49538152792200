<template>
  <div class="column content-box">
    <IntegrationTabs :selectedTabIndex = "selectedTabIndex" :onClickTabChange="onClickTabChange"/>
    
    <div class="row" v-if="selectedTabIndex === 0">
      <div class="row">
        <div class="small-12 columns integrations-wrap">
          <div class="row integrations">
            <div
              v-for="item in integrationsList"
              :key="item.id"
              class="small-12 columns integration"
            >
              <integration
                :integration-id="item.id"
                :integration-logo="item.logo"
                :integration-name="item.name"
                :integration-description="item.description"
                :integration-enabled="item.enabled"
                :integration-action="item.action"
              />
            </div>
            <div class="small-12 columns integration">
              <integration
                integration-id="dashboard-apps"
                integration-logo="dashboard-apps.svg"
                :integration-name="
                  $t('INTEGRATION_SETTINGS.DASHBOARD_APPS.TITLE')
                "
                :integration-description="
                  $t('INTEGRATION_SETTINGS.DASHBOARD_APPS.DESCRIPTION')
                "
                integration-enabled
                integration-action="/dashboard-apps"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <TabBotEva          :selectedTabIndex = "selectedTabIndex"/>
    <TabChannelsBotEva  :selectedTabIndex = "selectedTabIndex"/>
    <TabCRMZeus         :selectedTabIndex = "selectedTabIndex"/>
    <TabVocabulario     :selectedTabIndex = "selectedTabIndex"/>
    <TabSinonimos       :selectedTabIndex = "selectedTabIndex"/>
    <OpenAi             :selectedTabIndex = "selectedTabIndex"/>

  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import Integration from './Integration';
import IntegrationTabs from '../../../../extra/integrations/tabs/IntegrationTabs';
import TabBotEva from '../../../../extra/integrations/tabs/TabBotEva';
import TabChannelsBotEva from '../../../../extra/integrations/tabs/TabChannelsBotEva';
import TabCRMZeus from '../../../../extra/integrations/tabs/TabCRMZeus';
import TabVocabulario from '../../../../extra/integrations/tabs/TabVocabulario';
import TabSinonimos from '../../../../extra/integrations/tabs/TabSinonimos';
import OpenAi from '../../../../extra/integrations/openai/OpenAi';

export default {
  data() {
    return {
      selectedTabIndex: 0,
    }
  },
  components: {
    Integration,
    IntegrationTabs,
    TabBotEva,
    TabChannelsBotEva,
    TabCRMZeus,
    TabVocabulario,
    TabSinonimos,
    OpenAi,
  },
  computed: {
    ...mapGetters({
      integrationsList: 'integrations/getIntegrations',
    }),
  },
  mounted() {
    this.$store.dispatch('integrations/get');
  },
  methods: {
    async onClickTabChange(index) {
      this.selectedTabIndex = index;
    },
  },
};
</script>
