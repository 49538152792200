<template>
  <div class="small-12 columns integrations-wrap">
    <div class="row integrations">
      <div class="small-12 columns integration">
        <div class="row">
          <div class="column">
            <embedding-header
              :search-query="searchQueryEmbedding"
              :search-trained="searchTrainedEmbedding"
              :on-input-search="onInputSearchEmbedding"
              :on-search-submit="onSearchSubmitEmbedding"
              :on-toggle-create="onToggleCreateEmbedding"
              :on-toggle-pages="onTogglePages"
              :on-toggle-trained-filter="onToggleTrainedFilter"
              :on-toggle-origen-filter="onToggleOrigenFilter"
              :on-toggle-update="onToggleUpdateEmbedding"
              :on-toggle-update-foro="onToggleUpdateForo"
              :similarity = "similarity"
              
            />
          </div>
        </div>

        <div class="row">
          <div class="column">
            <table-footer
              @page-change="onPageChangeEmbedding"
              :current-page="Number(metaEmbedding.current_page)"
              :total-count="metaEmbedding.count"
              :page-size="metaEmbedding.page_size"
            />
          </div>
        </div>

        <div class="row">
          <div class="column">
            <embedding-table
              :data-table="dataEmbedding"
              :on-toggle-edit="onToggleTrained"
              :on-toggle-update="onToggleUpdate"
            />
          </div>
        </div>
        
      </div>
    </div>
  </div>
</template>
    
    <script>
import axios from "axios";
import { mapGetters } from "vuex";

import Integration from "../../../routes/dashboard/settings/integrations/Integration";

import EmbeddingTable from "./EmbeddingTable";
import EmbeddingHeader from "./EmbeddingHeader";
import TableFooter from "dashboard/components/widgets/TableFooter";

const DEFAULT_PAGE = 1;

export default {
  data() {
    return {
      searchQueryEmbedding: "",
      searchTrainedEmbedding: 2,
      dataEmbedding: {},
      metaEmbedding: {},
      perPages: 10,
      trainedFilter: 2,
      origenFilter: 0,
      currentPage: 1,
      similarity: 0.85,
    };
  },
  components: {
    Integration,
    EmbeddingTable,
    EmbeddingHeader,
    TableFooter,
  },
  computed: {
    ...mapGetters({
      currentUser: "getCurrentUser",
    }),
  },
  mounted() {
    this.getEmbedding(DEFAULT_PAGE);
  },
  methods: {
    async getEmbedding(current_page) {

      let { access_token, account_id } = this.currentUser;
      let searchQuery = this.searchQueryEmbedding;
      let searchTrained = this.searchTrainedEmbedding;
      let perPages = this.perPages;
      let trainedFilter = this.trainedFilter;
      let origenFilter = this.origenFilter;


      let result = await axios
        .get(process.env.URL_WEBHOOK + "/api/getEmbedding", {
          params: {
            access_token,
            account_id,
            current_page,
            searchQuery,
            searchTrained,
            perPages,
            trainedFilter,
            origenFilter
          },
        })
        .then(function (resp) {
          return resp.data;
        })
        .catch(function (error) {
          return error;
        });

      

      if (result.status == 200) {
        this.metaEmbedding = result.meta;
        this.currentPage = this.metaEmbedding.current_page;
        this.dataEmbedding = result.data;
        this.similarity = result.data[0].similarity;
      } else {
        this.metaEmbedding = {};
        this.currentPage = 1;
        this.dataEmbedding = {};
        this.similarity = 0.00;
      }
    },

    onSearchSubmitEmbedding() {
      if (this.searchQueryEmbedding) {
        this.getEmbedding(DEFAULT_PAGE);
      }
    },

    onInputSearchEmbedding(event) {
      const newQuery = event.target.value;
      const refetchAll = !!this.searchQueryEmbedding && newQuery === "";
      this.searchQueryEmbedding = newQuery;
      if (refetchAll) {
        this.getEmbedding(DEFAULT_PAGE);
      }
    },

    onPageChangeEmbedding(current_page) {
      this.getEmbedding(current_page);
    },

    onToggleUpdateEmbedding() {
      this.searchQueryEmbedding = "";
      this.getEmbedding(DEFAULT_PAGE);
    },

    async onToggleUpdateForo() {
      const { access_token, account_id } = this.currentUser;
      const data = {
        access_token, account_id 
      };
      const response = await axios
        .post(process.env.URL_WEBHOOK + "/api/updateForo", { data })
        .then(function (resp) {
          return resp.data;
        })
        .catch(function (error) {
          return error;
        });

        if (response.status === 200 ){
          this.getEmbedding(DEFAULT_PAGE);
        }  
        bus.$emit('newToastMessage', response.message);
        return true;

    },

    async onToggleTrained(data) {
      const response = await axios
        .post(process.env.URL_WEBHOOK + "/api/setEmbeddingOpenAI", { data })
        .then(function (resp) {
          return resp.data;
        })
        .catch(function (error) {
          return error;
        });

        if (response.status === 200 ){
          this.getEmbedding(this.currentPage);
          bus.$emit('newToastMessage', response.message);
          return true;
        } 
        const message = "Este documento no es posible entrenar, tal vez contiene demasiadas imágenes.";
        bus.$emit('newToastMessage', message);
        return true;
    },


    async onToggleUpdate(data) {
      const response = await axios
        .post(process.env.URL_WEBHOOK + "/api/updateArticle", { data })
        .then(function (resp) {
          return resp.data;
        })
        .catch(function (error) {
          return error;
        });

        if (response.status === 200 ){
          this.getEmbedding(this.currentPage);
          bus.$emit('newToastMessage', response.message);
          return true;
        } 
        // bus.$emit('newToastMessage', message);
        // return true;
    },
    async onTogglePages(event) {
      this.perPages = event.target.value;
      this.searchQueryEmbedding = "";
      this.getEmbedding(DEFAULT_PAGE);
    },
    async onToggleTrainedFilter(event) {
      this.trainedFilter = event.target.value;
      this.searchQueryEmbedding = "";
      this.getEmbedding(DEFAULT_PAGE);
    },
    async onToggleOrigenFilter(event) {
      this.origenFilter = event.target.value;
      this.searchQueryEmbedding = "";
      this.getEmbedding(DEFAULT_PAGE);
    }
  },
};
</script>
    
    <style scoped lang="scss">
@import "~dashboard/assets/scss/variables";
</style>