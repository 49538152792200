<template>
  <div class="row" v-if="selectedTabIndex === 5">
    <div class="small-12 columns integrations-wrap">
      <div class="row integrations">
        <div class="small-12 columns integration">
          <div class="row">
            <div class="integration--image">
              <img :src="'/dashboard/images/integrations/openia.svg'" />
            </div>
            <div class="column">
              <h3 class="integration--title">
                {{ integrationName }}
              </h3>
              <p class="integration--description">
                Las incrustaciones (Embeddings) de texto de OpenAI miden la
                relación de las cadenas de texto. Las incrustaciones se utilizan
                comúnmente para:
              </p>
              <p class="integration--description">
                Una incrustación es un vector (lista) de números de coma
                flotante. La distancia entre dos vectores mide su relación. Las
                distancias pequeñas sugieren una relación alta y las distancias
                grandes sugieren una relación baja.
              </p>
            </div>
            <div class="small-2 column button-wrap">
              <button @click="addDataResource" class="button nice">
                {{ "Configurar Entrenamiento" }}
              </button>
            </div>
          </div>
          <div class="row">
            <embedding-index />
          </div>
        </div>
      </div>
    </div>
    <woot-modal :show.sync="showDataResource" :on-close="hideDataResource">
      <DataResourceForm
        :on-close="hideDataResource"
        :data-resource="dataResource"
        :resource-types-external="ResourceTypesExternal"
      />
    </woot-modal>
  </div>
</template>
<script>
import axios from "axios";
import { mapGetters } from "vuex";
import DataResourceForm from "./DataResourceForm";
import EmbeddingIndex from "./EmbeddingIndex";

export default {
  data() {
    return {
      showDataResource: false,
      dataResource: {},
      ResourceTypesExternal: {},
    };
  },

  components: {
    DataResourceForm,
    EmbeddingIndex,
  },

  props: {
    selectedTabIndex: {
      type: Number,
      default: 0,
    },
  },

  computed: {
    ...mapGetters({
      currentUser: "getCurrentUser",
    }),
  },

  mounted() {
    this.getResourceTypesExternal();
  },

  methods: {
    hideDataResource() {
      this.showDataResource = false;
    },

    addDataResource() {
      const { account_id } = this.currentUser;
      this.dataResource = {
        id: 0,
        account_id: account_id,
        type_id: 0,
        description: "",
        resource_url: "",
        path: "",
        external: 1,
      };
      this.showDataResource = true;
    },

    onToggleShow(data) {
      this.showDataResource = data;
    },

    async getResourceTypesExternal() {
      const response = await axios
        .get(process.env.URL_WEBHOOK + "/api/getResourceTypesExternal", {})
        .then(function (response) {
          return response.data;
        })
        .catch(function (error) {
          return error;
        });
      this.ResourceTypesExternal = response;
    },
  },
  // computed: {
  //     ...mapGetters({
  //     integrationsList: 'integrations/getIntegrations',
  //     currentUser: 'getCurrentUser',
  //     }),

  // },

  // methods: {
  //     async setCuentaApi(){
  //         const { access_token, account_id } = this.currentUser;
  //         const api_type  = this.api_type;
  //         const api_access_token = this.api_access_token;
  //         const api_url_base  = this.api_url_base;
  //         const result = await axios.post(process.env.URL_WEBHOOK+'/api/setCuentasApi', {
  //             access_token, account_id, api_type, api_access_token, api_url_base,
  //         })
  //         .then(function (resp) { return resp.data; })
  //         .catch(function (error) { return error; });
  //         this.modoReadOnly = true;
  //         bus.$emit('newToastMessage', result.msg);
  //     },

  //     async getCuentaApi(){
  //         const { access_token, account_id } = this.currentUser;
  //         let result = await axios.get(process.env.URL_WEBHOOK+'/api/getCuentasApi', {
  //             params: {
  //                 access_token : access_token,
  //                 account_id     : account_id,
  //             }
  //         })
  //         .then(function (resp) { return resp.data; })
  //         .catch(function (error) { return error; });
  //         if (result.status === 200) {
  //             this.apps = result.apps;
  //             this.api_access_token  = result.api_access_token;
  //             this.api_url_base = result.api_url_base;
  //             this.api_type = result.api_type;
  //             this.modoReadOnly = true;
  //         }
  //     },

  //     async editCuentaApi(){
  //         this.modoReadOnly = false;
  //     },

  //     async deleteCuentaApi(){
  //         this.api_type =  0;
  //         this.api_url_base = "";
  //         this.api_access_token = "";
  //         this.setCuentaApi();
  //         this.modoReadOnly = false;

  //     },

  // }
};
</script>

<style scoped>
</style>
